
import http from "../../../../../common/services/http";
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import moment from "moment";
import { format } from "date-fns";
import NgongVegBanner from "@/components/NgongVegBanner.vue";

export default defineComponent({
  components: { NgongVegBanner },
  setup() {
    const warehouses = ref([]);
    const warehouse_id = ref(0);

    const farms = ref([]);
    const farm_id = ref(0);
    const processing = ref(false);

    const form = ref({});
    const day = ref(0);
    const date = ref(new Date());

    function wednesday() {
      const date = new Date();
      const last = new Date(new Date().getTime() - 6 * 24 * 60 * 60 * 1000);

      return date.getDay() < 3
        ? new Date(last.setDate(last.getDate() - (last.getDay() - 1) + 2))
        : new Date(date.setDate(date.getDate() - (date.getDay() - 1) + 2));
    }

    const start = ref(wednesday());
    const end = computed(
      () => new Date(new Date(start.value).getTime() + 6 * 24 * 60 * 60 * 1000)
    );

    function fD(d: any, f = "PPPP") {
      return format(d, f);
    }

    function getDates(s: any) {
      const e = new Date(new Date(s).getTime() + 6 * 24 * 60 * 60 * 1000);

      const dates = [];
      let currentDate = moment(s);
      const eDate = moment(e);
      while (currentDate <= eDate) {
        dates.push(moment(currentDate).format("YYYY-MM-DD"));
        currentDate = moment(currentDate).add(1, "days");
      }

      return dates;
    }

    function setDay(i = 0, d: any) {
      day.value = i;
      date.value = d;
    }

    function setBalanceAtWarehouse(f: any) {
      const received_total: any = [];
      f.receive.forEach((value: { received: any }) => {
        received_total.push(parseInt(value.received));
      });
      const received = received_total.reduce((sum: any, num: any) => sum + num);
      f.total_crates_in_circulation =
        parseInt(f.stock_at_warehouse) +
        parseInt(received) +
        parseInt(f.returned) +
        parseInt(f.broken_crates);
    }

    function calculateCratesInCirculationMinusCratesOwned(f: any) {
      f.crates_in_circulation_minus_crates_owned =
        parseInt(f.total_number_of_crates_owned) -
        (parseInt(f.total_crates_in_circulation) +
          parseInt(f.balance_at_warehouse));
    }

    function triggerCalculations(f: any) {
      setBalanceAtWarehouse(f);
      calculateCratesInCirculationMinusCratesOwned(f);
    }

    function fetchWarehouses() {
      processing.value = true;
      http.get(`/api/warehouses?fetch=all`).then(res => {
        warehouses.value = res.data;
        processing.value = false;
      });
    }

    function fetchFarms() {
      processing.value = true;
      http.get(`/api/farms?fetch=all`).then(res => {
        farms.value = res.data;
        processing.value = false;
      });
    }

    async function fetchCrates(id: any) {
      const res = await http.get(
        `/api/crates?warehouse_id=${id}&start=${fD(
          start.value,
          "yyyy-MM-dd"
        )}&end=${fD(end.value, "yyyy-MM-dd")}`
      );

      return res.data.data;
    }

    function fetchCratesBalance(id: any) {
      processing.value = true;

      fetchCrates(id).then((ps: any) => {
        http
          .get(
            `/api/get-crates-returned?warehouse_id=${id}&start=${fD(
              start.value,
              "yyyy-MM-dd"
            )}&end=${fD(end.value, "yyyy-MM-dd")}`
          )
          .then(res => {
            const crates = res.data.crates;
            const dates = getDates(start.value);

            form.value = dates.reduce((o, d) => {
              const p = crates[d];
              const a: any = ps.find((or: any) => or.date == d);
              return {
                ...o,
                [d]: {
                  receive: a?.quantities?.receive || [
                    {
                      received: parseInt(a?.quantities?.received) || 0,
                      received_note: a?.quantities?.received_note || ""
                    }
                  ],
                  returned: p?.balance || 0,
                  returned_note: a?.quantities?.returned_note || "",
                  broken_crates: parseInt(a?.quantities?.broken_crates) || 0,
                  balance_at_warehouse:
                    parseInt(a?.quantities?.balance_at_warehouse) || 0,
                  stock_at_warehouse:
                    parseInt(a?.quantities?.stock_at_warehouse) || 0,
                  total_crates_in_circulation:
                    parseInt(a?.quantities.total_crates_in_circulation) || 0,
                  crates_in_circulation_minus_crates_owned:
                    parseInt(
                      a?.quantities.crates_in_circulation_minus_crates_owned
                    ) || 0,
                  total_number_of_crates_owned:
                    parseInt(a?.quantities.total_number_of_crates_owned) || 0
                }
              };
            }, {});

            processing.value = false;
          });
      });
    }

    function saveCrates() {
      http
        .post("/api/crates", {
          warehouse_id: warehouse_id.value,
          crates: form.value,
          start: start.value,
          end: end.value
        })
        .then()
        .finally(() => {
          fetchCratesBalance(warehouse_id.value);
        });
    }

    function addRep(u: any) {
      u.push({
        received_note: "",
        received: 0
      });
    }

    function delRep(u: any, i: any) {
      u.splice(i, 1);
    }

    onMounted(() => {
      fetchWarehouses();
      warehouse_id.value = 1;
      fetchCratesBalance(1);
    });

    watch(
      () => end.value,
      (v: any) => {
        fetchCratesBalance(warehouse_id.value);
      }
    );

    return {
      start,
      end,
      fD,
      warehouses,
      warehouse_id,
      farms,
      farm_id,
      processing,
      fetchCratesBalance,
      getDates,
      day,
      setDay,
      date,
      form,
      saveCrates,
      setBalanceAtWarehouse,
      calculateCratesInCirculationMinusCratesOwned,
      addRep,
      delRep,
      triggerCalculations
    };
  }
});
